<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<RbAddToCartBtn></RbAddToCartBtn>

	2019-06-08	init

-->

<template>
	<button class="RbAddToCartBtn noSelect" :class="elmClasses" @click="onClickBtn">
		<span class="RbAddToCartBtn__label">
			Add to Cart
		</span>
		<span class="RbAddToCartBtn__price" v-html="getFormatedPrice(product.bruttoPrice)"></span>
	</button>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'
	import RbShopMixin from './RbShop/RbShop.mixin.js'

	export default {
		name: 'RbAddToCartBtn',
		components: {},
		mixins: [
			RbShopMixin,
		],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			product: {
				type     : [Object, Boolean],
				default  : false,
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {
			onClickBtn(){
				this.addCartItem( this.product )
			},
		},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.RbAddToCartBtn { // debug
	}
	.RbAddToCartBtn { // layout
		display: inline-block;
	}
	.RbAddToCartBtn { // styling
		transition: all @transitions[buttonTransitionDuration] ease-out;
		padding: 0.5em;

		color: currentColor;
		line-height: 1em;
		background-color: transparent;
		border: 2px solid currentColor;
		border-radius: 0.5em;

		&:hover {
			background-color: @swatches[textBlack];
			color: @swatches[bgWhite];
			border: 2px solid @swatches[textBlack];
			transform: translateY(-0.15em);
		}
		&:active {
			transform: translateY(0);
		}

		&__label, &__price {
			display: inline-block
		}
		&__label {
			padding-right: 0.5em;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
