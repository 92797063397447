<template>
	<MhRouterView class="RbProductView view " :isContentReady="isContentReady">

		<RbAppHeader
			:textRight="_.get( app, 'acfOptions.headerTextRight' )"
		></RbAppHeader>

		<RbAppMenu></RbAppMenu>

		<article class="view__inner hSpace hSpace--app">

			<div class="RbProductView__productGrid">

				<!-- cover -->
				<div class="RbProductView__productGridLeftColumn">
					<img class="RbProductView__cover" :src="product.coverImageUrl" />
					<div class="RbProductView__biblios font--sans font--sizeSmall"
						 v-if="product.biblios"
						 v-html="product.biblios"
					></div>
				</div>

				<!-- text -->
				<div class="RbProductView__productGridRightColumn">
					<!-- author, title, subTitle -->
					<h1 class="RbProductView__productGridRightColumnHeader RbProductView__productHeader font--serif font--sizeBig">
						<span class="RbProductView__author block" v-if="product.author">{{product.author}}</span>
						<span class="RbProductView__title block" v-if="product.title">{{product.title}}</span>
						<span class="RbProductView__subTitle block" v-if="product.subTitle">{{product.subTitle}}</span>
					</h1>
					<!-- addToCartBtn, descText -->
					<div class="RbProductView__productGridRightColumnBody font--sans font--sizeDefault">
						<div class="RbProductView__addToCartBtnWrapper">
							<RbAddToCartBtn
								v-if="product.bruttoPrice"
								:product="product"
							></RbAddToCartBtn>
						</div>
						<div class="RbProductView__descText richText" v-if="product.descText" v-html="product.descText"></div>
					</div>
				</div>

			</div>

			<!--
			<pre name="product">{{product}}</pre>
			<pre name="products">{{products}}</pre>
			-->

		</article>

	</MhRouterView>
</template>

<script>
	import { EventBus } from '@/event-bus.js'
	import RestHandler from "/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js"
	import MhRouterView from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhRouterView/v4/MhRouterView.vue'

	import RbShopMixin from '@/components/RbShop/RbShop.mixin.js'
	import RbAppHeader from "@/components/RbAppHeader.vue"
	import RbAppMenu from "@/components/RbAppMenu.vue"
	import RbProductTeaser from '@/components/RbProductTeaser.vue'
	import RbAddToCartBtn from '@/components/RbAddToCartBtn.vue'
	import RbAppFooter from "@/components/RbAppFooter.vue"

	export default {
		name: 'RbProductView',
		components: {
			MhRouterView,
			RbAppHeader,
			RbAppMenu,
			RbProductTeaser,
			RbAddToCartBtn,
			RbAppFooter,
		},
		mixins: [
			RestHandler,
			RbShopMixin,
		],
		directives: {},
		props: {},
		data() {
			return {
				isContentReady: false,
				products: undefined,
			}
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			product(){
				const product = this._.find( this.products, (o)=>{ return o.id == this.$route.params.slug })
				return product || {}
			},
		},
		methods: {
			fetchAll( finalCallback ){
				let thingsToLoad = 1

				const runCallback = ()=>{
					--thingsToLoad

					//console.log( this.$options.name, '• fetchAll() runCallback()', thingsToLoad)

					if( !thingsToLoad && this._.isFunction( finalCallback ) ) finalCallback()
				}

				// fetch and normalize products
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/products',
					params : {
						id : this.$route.params.slug
					},
					callback : (response) => {
						const results = response.data.result
						const normalizedProducts = []

						// normalize all products from db
						this._.forEach(results, (product)=>{
							normalizedProducts.push( this.normalizeProduct( product ) )
						})

						this.products = normalizedProducts

						runCallback()
					},
				})
			},
		},
		mounted(){
			this.fetchAll(()=>{
				//console.log( this.$options.name, '• fetchAll() • finalCallback()')

				this.isContentReady = true
			})
		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.RbProductView {
		[showBorders2] & {
			&__productGrid {
				& > * { outline: 1px solid red; }

				&LeftColumn {
					background-color: fade( red, 35 );
					& > * { outline: 1px solid red; }
				}
				&RightColumn {
					background-color: fade( orange, 35 );
					& > * { outline: 1px solid red; }
				}
			}
		}
	}
	.RbProductView { // layout
		&__productGrid {
			.grid;
			.grid--cols-12;
			.grid--rowGap;
			.grid--colGap;
			//.grid--setAreas;

			&LeftColumn {
				position: relative;
				grid-column: span 3;

				@media @mq[md] { grid-column: span 12; }
			}
			&RightColumn {
				grid-column: 5 / span 8;

				@media @mq[md] { grid-column: span 12; }
			}
			&RightColumnHeader {}
			&RightColumnBody {
				padding-left: 100/8%;

				@media @mq[md] { padding-left: 0; }
			}
		}
	}
	.RbProductView {
		&__cover {
			width: 100%;
			display: block;
		}
		&__biblios { padding-top: 1em; white-space: pre; }

		&__productHeader {
			padding: 0.75em 0;
		}
		&__author {}
		&__title {}
		&__subTitle {}

		&__author, &__title {
			padding-bottom: 0.25em;
		}

		&__addToCartBtnWrapper { }
		&__descText { padding-top: 1.75em; }
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
