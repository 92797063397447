<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<RbProductTeaser></RbProductTeaser>

	2019-06-08	init

-->

<template>
	<div class="RbProductTeaser" :class="elmClasses">
		<div class="routerLink RbProductTeaser__inner" >
			<div class="RbProductTeaser__topRow rbBorder rbBorder--bottom">
				<div class="RbProductTeaser__coverWrapper">
					<router-link class="RbProductTeaser__cover" :to="'/books/'+product.id+'/'" v-if="product"
						:style="'background-image : url('+product.coverImageUrl+')'">
					</router-link>
					<div class="RbProductTeaser__infoText richText font font--sans font--sizeSmall" v-if="infoText">
						<div class="RbProductTeaser__text" v-html="infoText"></div>
					</div>
				</div>
			</div>
			<div  class="RbProductTeaser__bottomRow font font--sans font--sizeSmall">
				<h2>
					<router-link :to="'/books/'+product.id+'/'" v-if="product">
						<span class="RbProductTeaser__author block" v-if="product.author">{{product.author}}</span>
						<span class="RbProductTeaser__title block" v-if="product.title">{{product.title}}</span>
						<!--
						<span class="RbProductTeaser__subTitle block" v-if="subTitle">{{subTitle}}</span>
						-->
					</router-link>
				</h2>
				<div class="RbProductTeaser__addToCartBtnWrapper" v-if="product.bruttoPrice">
					<RbAddToCartBtn
						:product="product"
					></RbAddToCartBtn>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import { EventBus } from '@/event-bus.js'
	import RbAddToCartBtn from './RbAddToCartBtn.vue'

	export default {
		name: 'RbProductTeaser',
		components: {
			RbAddToCartBtn,
		},
		mixins: [],
		props: {
			text: {
				type     : [String, Boolean],
				default  : false,
				required : false,
			},
			product: {
				type     : [Object, Boolean],
				default  : false,
				required : false,
			},
		},
		data(){
			return {
				isVisible : false
			}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if(this.isVisible) classes.push( this.$options.name + '--isVisible')
				if(this.product) classes.push( this.$options.name + '--isProduct')
				if(this.text && !this.product) classes.push( this.$options.name + '--isInfoText')

				return classes
			},
			infoText(){
				return this.text
			},
		},
		methods: {},
		created(){},
		mounted(){
			setTimeout(()=>{
				this.isVisible = true
			}, this._.random(50, 500))
		},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.RbProductTeaser { // debug
		[showBorders2] & {
			.label( "RbProductTeaser" );
			background-color: fade( red, 20 );
			outline: 1px solid fade( red, 50 );

			&__author {
				color: blue;
			}
			&__title {
				color: green;
			}
			&__subTitle {
				color: red;
			}
		}
	}
	.RbProductTeaser { // layout
		position: relative;
	}
	.RbProductTeaser { // styling
		transition: all @transitions[elementFadeInTransitionDuration] ease-out;
		opacity: 0;
		padding-bottom: 2em;

		&--isVisible {
			opacity: 1;
		}

		&__inner {
			color: @swatches[textBlack];
			transition: all @transitions[linkTransitionDuration] ease;
		}

		&__topRow {}
		&__bottomRow {}

		&__coverWrapper {
			position: relative;
			padding-bottom: 150%;
		}

		&__cover {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			transition: all @transitions[coverJumpTransitionDuration] ease-out @transitions[coverJumpTransitionDelay];

			background-size: contain;
			background-repeat: no-repeat;
			background-position: left bottom;
		}
		&--isProduct &__cover:hover {
			transform: translateY(-0.5em);
			transition-delay: 0s;
		}

		&__infoText {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			overflow: hidden;
			display: flex;
			align-items: flex-end;
			padding: 0.5em 0;

			& > * {
				//display: flex;
				//flex-direction: column;
				//align-content: flex-end;
			}
		}

		&__author { padding: 0.5em 0; }
		&__title {}
		&__subTitle {}
		&__addToCartBtnWrapper { padding-top: 0.75em; }

		&__bottomRow a { color: inherit; }
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
